<template>
    <ui-dropdown align="right" z-index="50" width="w-56">
        <template v-slot:trigger>
            <a href="#" class="flex text-2xl text-blue-600 hover:text-blue-900" v-tooltip="'Settings'">
                <ui-icon name="settings"></ui-icon>
            </a>
        </template>

        <template v-slot:content="{ close }">
            <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1 cursor-pointer">
                Search In
            </div>

            <ui-dropdown-link icon="type" :selected="! hasSearchFlag('no-title')" @click="toggleSearchFlag('no-title')" keep-open>
                Content Title
            </ui-dropdown-link>
            <ui-dropdown-link icon="align-left" :selected="! hasSearchFlag('no-body')" @click="toggleSearchFlag('no-body')" keep-open>
                Content Text
            </ui-dropdown-link>
            <ui-dropdown-link icon="image" :selected="! hasSearchFlag('no-images')" @click="toggleSearchFlag('no-images')" keep-open>
                Images Text
            </ui-dropdown-link>
            <ui-dropdown-link icon="video" :selected="! hasSearchFlag('no-videos')" @click="toggleSearchFlag('no-videos')" keep-open>
                Video Transcription
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link :selected="hasSearchFlag('exact-phrases')" @click="toggleSearchFlag('exact-phrases')" keep-open>
                Exact matching in quotes
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="time" @click="openSearchHistoryModal('content')">
                Search History
            </ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>
import useModalsSearchHistoryStore from '@/stores/modals/search-history'

import { mapActions } from 'pinia'

export default {
    methods: {
        ...mapActions(useModalsSearchHistoryStore, { openSearchHistoryModal: 'open' }),

        hasSearchFlag(flag) {
            return this.store.search.flags.includes(flag)
        },

        toggleSearchFlag(type) {
            this.store.toggleSearchFlag(type)
        }
    }
}
</script>
